import request from '@/utils/request'
import { parseStrEmpty } from '@/utils/ruoyi'




// 查询用户列表分页
export function pageUser (query) {
  return request({
    url: '/system/users/page',
    method: 'get',
    params: query
  })
}


// 查询用户列表
export function listUser (query) {
  return request({
    url: '/system/users/list',
    method: 'get',
    params: query
  })
}

// 查询用户详细
export function getUser (userId) {
  return request({
    url: '/system/users/' + parseStrEmpty(userId),
    method: 'get'
  })
}

// 新增用户
export function addUser (data) {
  return request({
    url: '/system/users',
    method: 'post',
    data: data
  })
}

// 修改用户
export function updateUser (data) {
  return request({
    url: '/system/users',
    method: 'put',
    data: data
  })
}

// 删除用户
export function delUser (userId) {
  return request({
    url: '/system/users/' + userId,
    method: 'delete'
  })
}

// 用户密码重置
export function resetUserPwd (id, password) {
  const data = {
    id,
    password
  }
  return request({
    url: '/system/users/resetPwd',
    method: 'put',
    data: data
  })
}

// 用户状态修改
export function changeUserStatus (id, status) {
  const data = {
    id,
    status
  }
  return request({
    url: '/system/users/changeStatus',
    method: 'put',
    data: data
  })
}

// // 查询用户个人信息
// export function getUserProfile () {
//   return request({
//     url: '/system/users/profile',
//     method: 'get'
//   })
// }
export function getUserData (value) {
  return request({
    url: '/biz/app-feedbacks/getUserData',
    method: 'get',
    params:value
  })
}

export function getDiomnData (value) {
  return request({
    url: '/biz/app-feedbacks/getDiomnData',
    method: 'get',
    params:value
  })
}
export function updateDisDiomn (value) {
  return request({
    url: '/biz/app-feedbacks/updateDisDiomn',
    method: 'get',
    params:value
  })
}

export function updateOrderDiomn (value) {
  return request({
    url: '/biz/app-feedbacks/updateOrderDiomn',
    method: 'get',
    params:value
  })
}

export function updateRechargeDiomn (value) {
  return request({
    url: '/biz/app-feedbacks/updateRechargeDiomn',
    method: 'get',
    params:value
  })
}

export function getBuyData (value) {
  return request({
    url: '/biz/app-feedbacks/getBuyData',
    method: 'get',
    params:value
  })
}



export function getVipData (value) {
  return request({
    url: '/biz/app-feedbacks/getVipData',
    method: 'get',
    params:value
  })
}


export function getUserDayData () {
  return request({
    url: '/biz/app-feedbacks/getUserDayData',
    method: 'get'
  })
}

export function getDisUserData (value) {
  return request({
    url: '/biz/app-feedbacks/getDisUserData',
    method: 'get',
    params:value
  })
}


// 修改用户个人信息
export function updateUserProfile (data) {
  return request({
    url: '/system/users/profile',
    method: 'put',
    data: data
  })
}

// 用户密码重置
export function updateUserPwd (oldPassword, newPassword) {
  const data = {
    oldPassword,
    newPassword
  }
  return request({
    url: '/system/users/profile/updatePwd',
    method: 'put',
    params: data
  })
}

// 用户头像上传
export function uploadAvatar (data) {
  return request({
    url: '/system/users/profile/avatar',
    method: 'post',
    data: data
  })
}

// 导入用户
export function importData (data) {
  return request({
    url: '/system/users/importData',
    method: 'post',
    data: data
  })
}



export function getRuioDataByDay () {
  return request({
    url: '/biz/app-feedbacks/getRuioDataByDay',
    method: 'get',
  })
}

// 查询授权角色
export function getAuthRole (userId) {
  return request({
    url: '/system/users/authRole/' + userId,
    method: 'get'
  })
}

// 保存授权角色
export function updateAuthRole (data) {
  return request({
    url: '/system/users/authRole',
    method: 'put',
    params: data
  })
}

// 查询部门下拉树结构
export function deptTreeSelect () {
  return request({
    url: '/system/users/deptTree',
    method: 'get'
  })
}


// 统计机构日增量
export function getInstutionByDay () {
  return request({
    url: '/biz/app-feedbacks/getInstutionByDay',
    method: 'get'
  })
}


export function getDisInstutionByDay () {
  return request({
    url: '/biz/app-feedbacks/getDisInstutionByDay',
    method: 'get'
  })
}

export function getMoneyInstutionByDay () {
  return request({
    url: '/biz/app-feedbacks/getMoneyInstutionByDay',
    method: 'get'
  })
}

export function getMoneyRechargePal () {
  return request({
    url: '/biz/app-feedbacks/getMoneyRechargePal',
    method: 'get'
  })
}

export function getRechargePal () {
  return request({
    url: '/biz/app-feedbacks/getRechargePal',
    method: 'get'
  })
}

export function getDisRechargePal () {
  return request({
    url: '/biz/app-feedbacks/getDisRechargePal',
    method: 'get'
  })
}


export function getBuyVipData () {
  return request({
    url: '/biz/app-feedbacks/getBuyVipData',
    method: 'get'
  })
}


// 统计门店日增量
export function getStoreByDay () {
  return request({
    url: '/platform/platform-institution/getStoreByDay',
    method: 'get'
  })
}

// 统计（用户日增量、用户总数量、男生数量、女生数量）
export function getALLCollectNum () {
  return request({
    url: '/biz/app-feedbacks/getALLCollectNum',
    method: 'get'
  })
}
